import React, { useState, useEffect } from "react";
import firebaseApp, { db } from "../../firebase";
import { Alert, Box, Button, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Link, useNavigate } from "react-router-dom";

export default () => {
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState(false);

  let navigate = useNavigate();

  // Get the oobCode in the URL
  const getParameter = () => {
    const matches = /oobCode=([^&#=]*)/.exec(window.location.search);
    if (matches) return matches[1];

    return undefined;
  };

  const handleVerifyEmail = () => {
    const code = getParameter();

    if (!code) {
      setVerifyLoading(false);
      setError(true);
      return;
    }

    firebaseApp
      .auth()
      .applyActionCode(getParameter())
      .then((resp) => {
        setVerifyLoading(false);
        setVerified(true);

        setTimeout(() => {
          return navigate("/dashboard");
        }, 3000);
      })
      .catch((error) => {
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
        setVerifyLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    handleVerifyEmail();
  }, []);

  let message;

  if (verifyLoading)
    message = (
      <Typography variant="h4" sx={{ mt: { xs: 4, sm: 10 }, mb: 2 }}>
        Verifying your email...
      </Typography>
    );

  if (error)
    message = (
      <>
        <Typography variant="h4" sx={{ mt: { xs: 4, sm: 10 }, mb: 2 }}>
          Your link has expired
        </Typography>

        {/* {dbError ? (
          <Alert severity="error">
            Could not resend email. Please try again later.
          </Alert>
        ) : null} */}

        <Typography variant="body">
          <p>
            Your request to verify your email has expired or the link has
            already been used. Please try verifying your email again.
          </p>
        </Typography>

        <Link to="/login">
          <Button>Return to Login</Button>
        </Link>
      </>
    );

  if (verified)
    message = (
      <>
        <Typography variant="h4" sx={{ mt: { xs: 4, sm: 10 }, mb: 2 }}>
          Your email has been verified
        </Typography>

        <Typography variant="body">
          <p>
            Thanks for joining HealthDesk. Please wait, you will be redirected
            shortly.
          </p>
        </Typography>
      </>
    );

  return (
    <Box sx={{ textAlign: "center", maxWidth: "sm", m: "auto" }}>{message}</Box>
  );
};
