import React, { useState, useEffect } from "react";
import firebaseApp, { db } from "../../firebase";
import firebase from "firebase/compat/app";
import usePosition from "hooks/usePosition";
import axios from "axios";

export const ZipContext = React.createContext();

export const ZipProvider = ({ children }) => {
  const { latitude, longitude, error } = usePosition();

  const [zip, setZip] = useState();
  const [customLocation, setCustomLocation] = useState();
  const [branding, setBranding] = useState();
  const [brandingLoaded, setBrandingLoaded] = useState(false);
  const [groups, setGroups] = useState();

  const getBranding = (zip) => {
    return db
      .collection("zips")
      .doc(String(zip))
      .collection("ads")
      .get()
      .then((querySnapshot) => {
        let newGroups = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data();

          if (data.owner) {
            setBranding(data.branding);
          } else {
            newGroups.push(doc.data());
          }
        });

        if (newGroups.length > 0) setGroups(newGroups);
        setBrandingLoaded(true);
      });
  };

  useEffect(() => {
    let lat = customLocation?.lat || latitude;
    let lng = customLocation?.lng || longitude;
    let clZip = customLocation?.zip;

    if (!clZip) {
      // TODO: Get a working ZIP code API
      // const url = `https://zip-api.eu/api/v1/info/${lat},${lng}/5`;
      // axios
      //   .get(url)
      //   .then((res) => {
      //     const zipFromApi = res.data.postal_code;
      //     setZip(zipFromApi);
      //     getBranding(zipFromApi);
      //   })
      //   .catch((error) => {
      //     console.error("error getting zipcode");
      //   });
      setBrandingLoaded(true);
    } else {
      setZip(clZip);
      getBranding(clZip);
    }
  }, [latitude, longitude, customLocation]);

  return (
    <ZipContext.Provider
      value={{
        zip,
        setCustomLocation,
        branding,
        brandingLoaded,
        groups,
      }}
    >
      {children}
    </ZipContext.Provider>
  );
};
