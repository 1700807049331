import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";

const AuthWrapper = ({ background, title, children, left, facility }) => {
  return (
    <>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={12}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: left ? "top left" : "top center",
            minHeight: "8rem",
            p: { xs: 0, md: 8 },
            textAlign: "center",
            display: "flex",
            justifyContent: facility
              ? { xs: "center", md: "flex-end" }
              : "center",
            alignItems: facility
              ? { xs: "center", md: "flex-start" }
              : "center",
          }}
        >
          {title ? (
            <Typography
              variant="h5"
              sx={{
                fontSize: { xs: "1.5rem", sm: "2.125rem" },
                color: "#fff",
                p: 2,
              }}
            >
              {title}
            </Typography>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box style={{ maxWidth: "40rem", width: "100%" }}>{children}</Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AuthWrapper;
