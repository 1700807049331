import React, { useState, useEffect, useContext, useRef } from "react";
import { Box, Chip, Link, Typography, capitalize } from "@mui/material";
import MiniMap from "./MiniMap";
import medications from "./medications";
import diseases from "./diseases";
import NewDiseasesBox from "./NewDiseasesBox";
import NewMedicationBox from "./NewMedicationBox";
import { ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";
import Chatbot from "./Chatbot";

const ContextBox = ({
  branding,
  messages,
  user,
  userData,
  tabs,
  setTabs,
  currentTab,
  setCurrentTab,
  expanded,
  setExpanded,
  boxRef,
}) => {
  const mapTriggers = [
    "healthcare provider",
    "seek appropriate medical care",
    "Emergency Department",
    "emergency department",
  ];

  const navRef = useRef(null);
  const [medicationsList, setMedicationsList] = useState([]);
  const [diseasesList, setDiseasesList] = useState([]);

  const [xsSize, setXsSize] = useState("calc(20dvh - 24px)");
  const [smSize, setSmSize] = useState("calc(20dvh - 24px)");

  useEffect(() => {
    if (messages.length === 0) {
      setTabs([]);
      setCurrentTab("home");
      return;
    }

    // if (tabs.includes("map")) return;

    let medicationsList = [];
    let diseasesList = [];

    messages.map(({ message, sender }) => {
      if (!message || sender !== "HealthDesk") return;

      const medicationsFound = medications.filter((s) =>
        message?.toLowerCase().includes(s?.toLowerCase())
      );

      medicationsList = medicationsList.concat(medicationsFound);

      setMedicationsList(medicationsList);

      const diseasesFound = diseases.filter((s) =>
        s.length > 4
          ? message?.toLowerCase().includes(s?.toLowerCase())
          : message?.includes(s)
      );

      diseasesList = diseasesList.concat(diseasesFound);

      setDiseasesList(diseasesList);

      if (!tabs.includes("map")) {
        const found = mapTriggers.some((trigger) => message.includes(trigger));

        if (found) {
          setTabs([...tabs, "map"]);
          setCurrentTab("map");
        }
      }
    });
  }, [messages]);

  useEffect(() => {
    if (tabs.length > 0) setCurrentTab(tabs[tabs.length - 1]);
  }, [tabs]);

  const scrollTo = (ref) => ref.current.scrollIntoView({ behavior: "smooth" });
  const scrollToTop = () =>
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  useEffect(() => {
    let newTabs = [];

    if (medicationsList.length > 0) {
      if (!tabs.includes("medications")) {
        newTabs.push("medications");
      }
    }

    if (diseasesList.length > 0) {
      if (!tabs.includes("diseases")) {
        newTabs.push("diseases");
      }
    }

    if (newTabs.length > 0) setTabs([...tabs].concat(newTabs));
  }, [medicationsList, diseasesList]);

  useEffect(() => {
    if (expanded) {
      setXsSize("calc(100dvh - 80px)");
      setSmSize("calc(100dvh - 24px)");
    } else if (tabs.length === 0) {
      setXsSize("0");
      setSmSize("0");
    } else {
      setXsSize("calc(20dvh - 24px)");
      setSmSize("calc(20dvh - 24px)");
    }
  }, [expanded, tabs]);

  return (
    <Box
      sx={{
        height: {
          xs: xsSize,
          sm: smSize,
        },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
      ref={boxRef}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "calc(100% - 40px)",
        }}
      >
        {expanded ? (
          <Chatbot
            visible={currentTab === "home" && expanded}
            branding={branding}
            user={user}
            userData={userData}
            setCurrentTab={setCurrentTab}
            expanded={expanded}
            boxRef={boxRef}
          />
        ) : null}
        {/* Map box */}
        {tabs.includes("map") ? (
          <MiniMap visible={currentTab === "map"} expanded={expanded} />
        ) : null}
        {/* Medications box */}
        {tabs.includes("medications") ? (
          <NewMedicationBox
            medications={medicationsList}
            visible={currentTab === "medications"}
            expanded={expanded}
          />
        ) : null}
        {/* Diseases box */}
        {tabs.includes("diseases") ? (
          <NewDiseasesBox
            diseases={diseasesList}
            visible={currentTab === "diseases"}
            expanded={expanded}
          />
        ) : null}
      </Box>

      {tabs.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            width: "calc(100% + 50px)",
            paddingTop: "0.5rem",
            overflowX: "auto",
            marginLeft: "-50px",
            marginRight: "-50px",
            paddingLeft: "25px",
            paddingRight: "25px",
            height: 46,
            alignItems: "flex-end",
          }}
          ref={navRef}
        >
          {expanded ? (
            <Chip
              label="Chat"
              variant={currentTab === "home" ? "filled" : "outlined"}
              onClick={() => {
                setCurrentTab("home");
                scrollToTop();
              }}
            />
          ) : null}

          {tabs.map((tab, i) => (
            <Chip
              key={i}
              label={capitalize(tab)}
              variant={currentTab === tab ? "filled" : "outlined"}
              onClick={() => {
                setCurrentTab(tab);
                scrollToTop();
              }}
            />
          ))}

          <Chip
            sx={{ marginLeft: "auto" }}
            color="primary"
            variant="contained"
            icon={expanded ? <ExpandLessRounded /> : <ExpandMoreRounded />}
            label={expanded ? "Show Less" : "Expand"}
            onClick={() => {
              // If we click "show less" and are on the chat tab, redirect to the nearest tab
              if (expanded && currentTab === "home") {
                setCurrentTab(tabs[0]);
              }

              setExpanded(!expanded);
            }}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default ContextBox;
