import React, { useEffect, useState } from "react";
import Loading from "../Loading";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import styled from "styled-components";
import firebaseApp, { db } from "../../firebase";
import { useForm } from "react-hook-form";
import { useStripe } from "@stripe/react-stripe-js";
import Error from "../styled/Error";
import DiscountCode from "./DiscountCode";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Link,
  Typography,
} from "@mui/material";
import MuBox from "components/mui/MuBox";
import { DisabledByDefault } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

const InputWrapper = styled.div`
  border-top: ${(props) => (props.top ? "0.55px solid #c8c7cc" : "none")};
  padding-top: ${(props) => (props.top ? "10px" : "0")};
  border-bottom: 0.55px solid #c8c7cc;
  margin-bottom: 10px;

  h2 {
    font-size: 1.25rem !important;
    text-align: left !important;
  }

  p {
    color: #1b4584;
    margin-bottom: 2.5rem;
  }
`;

const TwoColumn = styled.div`
  display: flex;
  padding-bottom: 5px;
`;

const Label = styled.label`
  display: block;
  width: 100%;
  font-size: ${({ $large }) => ($large ? "14px" : "12px")};
`;

const Pricing = ({ uid, role, subscription }) => {
  const stripe = useStripe();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState();
  const [codeOpen, setCodeOpen] = useState(false);
  const [trial, setTrial] = useState(false);
  const [prices, setPrices] = useState({});
  const [currentSubscription, setCurrentSubscription] = useState();
  const [subscriptionLoaded, setSubscriptionLoaded] = useState();

  const getPlans = async () => {
    if (role)
      db.collection("plans")
        .where("active", "==", true)
        .where("role", "==", role)
        .get()
        .then((querySnapshot) =>
          querySnapshot.forEach(async (doc) => {
            const productData = doc.data();

            db.collection("plans")
              .doc(doc.id)
              .collection("prices")
              .where("active", "==", true)
              .get()
              .then((priceSnap) => {
                let priceList = [];

                priceSnap.forEach((price) => {
                  const priceData = price.data();

                  const roundNumber =
                    String(priceData.unit_amount).slice(-2) === "00";
                  const newPrice = {
                    id: price.id,
                    role: productData.role,
                    price: `$${
                      roundNumber
                        ? priceData.unit_amount / 100
                        : (priceData.unit_amount / 100).toFixed(2)
                    }`,
                    interval: priceData.interval,
                  };

                  priceList[newPrice.interval] = newPrice;
                });

                setPrices(priceList);
              });
          })
        );
  };

  useEffect(() => {
    getPlans();
    getSubscription();
  }, [role]);

  const sendToPortal = async () => {
    setLoading(true);

    const functionRef = firebase
      .app()
      .functions("us-central1")
      .httpsCallable("ext-firestore-stripe-payments-createPortalLink");
    const { data } = await functionRef({
      returnUrl: window.location.href,
    });
    window.location.assign(data.url);
  };

  const sendToCheckout = (plan) => {
    setLoading(plan);

    const checkout =
      role === "patient"
        ? {
            price: plan,
            trial_period_days: trial ? 7 : 1,
            billing_address_collection: "auto",
            payment_method_collection: "if_required",
            success_url: window.location.origin + "/dashboard", // redirect user to this screen after
            cancel_url: window.location.href,
          }
        : {
            price: plan,
            success_url: window.location.origin + "/dashboard", // redirect user to this screen after
            cancel_url: window.location.href,
          };

    db.collection("users")
      .doc(uid)
      .collection("checkout_sessions")
      .add(checkout)
      .then((docRef) => {
        docRef.onSnapshot((snap) => {
          const { error, sessionId } = snap.data();
          if (error) {
            alert(`An error occurred: ${error.message}`);
          }

          if (sessionId) {
            stripe.redirectToCheckout({ sessionId });
          }
        });
      });
  };

  // fetch request for user subscription
  const getSubscription = () => {
    db.collection("users")
      .doc(uid)
      .collection("subscriptions")
      .where("status", "in", ["active", "trialing", "past_due", "canceled"])
      .onSnapshot((snapshot) => {
        const document = snapshot.docs[0];
        if (document?.data().status) {
          setCurrentSubscription(document?.data().status);
        }

        setSubscriptionLoaded(true);
      });
  };

  if (
    currentSubscription === "past_due" ||
    currentSubscription === "canceled"
  ) {
    return (
      <Box>
        <Typography variant="body">
          <p>Your subscription has expired.</p>

          <p>
            To continue using the Medical SuperIntelligence, please add your
            billing information below.
          </p>

          <LoadingButton
            loading={loading}
            variant="contained"
            disabled={loading}
            onClick={sendToPortal}
          >
            Subscribe Now
          </LoadingButton>
        </Typography>
      </Box>
    );
  }

  return Object.keys(prices).length > 0 ? (
    <>
      <Box>
        {role === "patient" ? (
          <>
            <Typography variant="body">
              <p>
                <strong>For all our plans, get a 24 hour free trial.</strong> No
                credit card required.
              </p>
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Link
                underline="none"
                sx={{ pb: 2, cursor: "pointer" }}
                onClick={() => setCodeOpen(!codeOpen)}
              >
                Have a discount code?
              </Link>
              <DiscountCode show={codeOpen} setTrial={setTrial} />
              <Alert severity="info" sx={{ mt: 2, mb: 3 }}>
                <AlertTitle>Important Subscriber Information</AlertTitle>
                Thank you for considering a subscription with us! We want to
                ensure the best experience for all users, so please be aware
                that all subscriptions are subject to use restrictions. These
                guidelines are in place to help maintain the overall system's
                viability and ensure fair access for everyone. We appreciate
                your understanding and cooperation.
              </Alert>
            </Box>
          </>
        ) : null}
      </Box>

      <Price
        title={"Monthly Plan"}
        price={prices.month}
        sendToCheckout={sendToCheckout}
        loading={loading}
      />
      <Price
        title={"Yearly Plan"}
        price={prices.year}
        sendToCheckout={sendToCheckout}
        loading={loading}
      />
      <Price
        title={"Day Pass"}
        description={"Pay as you go."}
        price={prices.day}
        sendToCheckout={sendToCheckout}
        loading={loading}
      />
    </>
  ) : (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Loading page />
    </div>
  );
};

export default Pricing;

const Price = ({ title, description, price, sendToCheckout, loading }) => {
  if (price === undefined) return;

  return (
    <MuBox
      key={price.id}
      title={title}
      description={
        <>
          <strong>
            {price.price}/{price.interval}
          </strong>
          . {description ? description : "Cancel anytime."}
        </>
      }
      links={[
        {
          title: "Select this plan",
          onClick: () => sendToCheckout(price.id),
          loading: loading,
          loadingCondition: loading === price.id,
        },
      ]}
    />
  );
};
