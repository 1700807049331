import React, { useContext, useEffect, useRef, useState } from "react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { Link as RouterLink } from "react-router-dom";
import {
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
  Button as ChatButton,
  ChatContainer,
  ConversationHeader,
} from "@chatscope/chat-ui-kit-react";
import firebase from "firebase/compat/app";
import reactStringReplace from "react-string-replace";
import parse from "html-react-parser";
import Markdown from "react-markdown";
import diseases from "components/chatbot/diseases";
import medications from "components/chatbot/medications";

import logo from "logo-icon.png";
import styled from "styled-components";
import { Link } from "react-router-dom";
import TextLink from "../styled/TextLink";
import Button from "../styled/Button";
import { db } from "../../firebase";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa6";
import UserProfileSettings from "./UserProfileSettings";
import { ChatContext } from "components/context/Chat";
import { Alert, Box, Snackbar, Typography } from "@mui/material";
import {
  ArrowDropDown,
  ArrowDropDownRounded,
  ArrowDropUpRounded,
} from "@mui/icons-material";
import { ZipContext } from "components/context/Zip";

const OptionsWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  bottom: 40px;
  text-align: center;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;

  @media screen and (max-width: 599px) {
    justify-content: flex-end;
    padding-bottom: 30px;
  }

  img {
    max-width: 64px;
    margin: -40px auto -10px;
  }

  h3 {
    text-transform: uppercase;
    margin-bottom: -15px;
  }

  div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
  }

  .branding {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    margin: 0;
    display: block;

    img {
      max-height: 70px;
      max-width: 100%;
    }
  }

  .info {
    top: 60px;
    left: 0;
    right: 0;
    margin: 0;
    position: absolute;
    display: block;
  }

  .disclaimer {
    color: #1b4584;
    display: block;
    font-size: 0.75rem;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;

    label {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3px;
    }
  }
`;

const ChatWrapper = styled(ChatContainer)`
  .cs-message--incoming .cs-message__content {
    background-color: #eee;
  }

  .cs-message--outgoing .cs-message__content {
    color: #fff;
    background-color: #117aca;
  }

  .cs-button--send {
    color: #117aca;
  }

  .cs-message-input__content-editor-wrapper,
  .cs-message-input__content-editor {
    background-color: #eee;
  }

  .ps__rail-y {
    opacity: 0.8;
    width: 10px;
  }

  .ps__rail-y:hover > .ps__thumb-y,
  .ps__rail-y:active > .ps__thumb-y,
  .ps__rail-y:focus > .ps__thumb-y,
  .ps__rail-y.ps--clicking > .ps__thumb-y,
  .ps__thumb-y {
    background-color: #686e72;
    width: 6px;
  }

  .ps__rail-y:hover,
  .ps__rail-y:active,
  .ps__rail-y:focus,
  .ps__rail-y.ps--clicking {
    background-color: #f6f7f8;
    opacity: 1;
  }

  .cs-message-list {
    height: ${({ $expanded, $full }) =>
      $expanded || $full ? "90dvh" : "76.5dvh"};
  }

  @media screen and (max-width: 599px) {
    .cs-message-list {
      height: ${({ $expanded, $full }) =>
        $expanded || $full ? "calc(90dvh - 100px)" : "calc(80dvh - 100px)"};
    }
  }

  .cs-message-input {
    bottom: ${({ $expanded }) => ($expanded ? "50px !important" : "0")};
  }
`;

const MessageWrapper = styled.div`
  button {
    background: transparent;
    border: none;
    opacity: 0.5;
    padding-top: 0.375rem;
    padding-right: 0.625rem;
    padding-bottom: 0.25rem;
    cursor: pointer;
  }

  button:hover {
    opacity: 1;
  }

  font-size: 12px;
  color: #1b4584;
  line-height: 27px;
  height: 27px;
`;

var medicationsList = new RegExp(`(${medications.join("|")})`, "gi");
var diseasesList = new RegExp(`(${diseases.join("|")})`, "gi");
var bold = /\*\*(.*?)\*\*/gm;

const Chatbot = ({
  visible,
  user,
  userData,
  setCurrentTab,
  expanded,
  tabs,
  boxRef,
}) => {
  const messageRef = useRef(null);
  const {
    messages,
    setMessages,
    thread,
    rateLimited,
    limit,
    setKeyword,
    setDescOpen,
  } = useContext(ChatContext);
  const { branding, brandingLoaded, zip } = useContext(ZipContext);

  const [isTyping, setIsTyping] = useState(false);
  const [submitInfo, setSubmitInfo] = useState(true);
  const [reported, setReported] = useState([]);

  // const handleFeedback = async (message, id, positive) => {
  //   const newFeedback = {
  //     feedback: firebase.firestore.FieldValue.arrayUnion({
  //       rating: positive ? "positive" : "negative",
  //       message: message,
  //     }),
  //     thread: thread,
  //   };

  //   db.collection("feedback")
  //     .doc(user.uid)
  //     .set(newFeedback, { merge: true })
  //     .then(() => {
  //       console.log("Feedback has been posted.");
  //     });

  //   setReported([...reported, id]);
  // };

  const handleSendRequest = async (message) => {
    const newMessage = {
      message,
      direction: "outgoing",
      sender: "user",
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setIsTyping(true);

    try {
      await processMessageToChatGPT(newMessage);
    } catch (error) {
      console.error("Error processing message:", error);
    }
  };

  async function processMessageToChatGPT(message) {
    if (!user) return;

    let newMessage = {
      messages: firebase.firestore.FieldValue.arrayUnion({
        sender: message.sender,
        message: message.message,
        created: Math.round(Date.now() / 1000),
      }),
    };

    if (submitInfo) {
      if (userData?.profile) newMessage.user = {};
      if (userData?.profile?.sex) newMessage.user.sex = userData.profile?.sex;
      if (userData?.profile?.age) newMessage.user.age = userData.profile?.age;
    }

    const subdomain = window.location.host.split(".")[0];
    if (
      subdomain === "one" ||
      subdomain === "two" ||
      subdomain === "three" ||
      subdomain === "qld"
    ) {
      newMessage.assistant = subdomain;
    }

    if (thread) {
      db.collection("chat")
        .doc(user.uid)
        .collection("threads")
        .doc(thread)
        .set(newMessage, { merge: true })
        .then(() => {
          console.log("Message has been posted.");
        });
    } else {
      db.collection("chat")
        .doc(user.uid)
        .collection("threads")
        .doc(String(Date.now()))
        .set(newMessage, { merge: true })
        .then(() => {
          console.log("Message has been posted.");
        });
    }
  }

  const reactStringReplaceRecursive = (input, pattern, fn, key = 0) => {
    const isEmpty = (item) => {
      if (!item) return true;
      if (item.hasOwnProperty("props")) {
        return false;
      } else {
        return item.length ? false : true;
      }
    };

    if (!input) {
      return null;
    } else if (typeof input === "string") {
      return reactStringReplace(input, pattern, fn);
    }

    var output = [];
    for (var i = 0; i < input.length; i++) {
      const item = input[i];
      if (item) {
        if (typeof item === "string") {
          const next = reactStringReplace(item, pattern, fn);
          if (!isEmpty(next)) output.push(next);
        } else if (typeof item === "object") {
          if (
            item.hasOwnProperty("props") &&
            item.props.hasOwnProperty("children")
          ) {
            const next = reactStringReplaceRecursive(
              item.props.children,
              pattern,
              fn,
              key + 1
            );
            if (!isEmpty(next)) {
              const props = Object.assign(
                { key: "k" + key + "i" + i },
                item.props
              );
              output.push(React.createElement(item.type, props, next));
            }
          } else {
            const next = reactStringReplaceRecursive(
              item,
              pattern,
              fn,
              key + 1
            );
            if (!isEmpty(next)) output.push(next);
          }
        }
      }
    }

    return output;
  };

  useEffect(() => {
    if (messages?.length > 0) {
      if (messages[messages.length - 1].sender === "HealthDesk")
        setIsTyping(false);
    }

    if (messageRef) scrollTo(messageRef);
  }, [messages]);

  const openDesc = (tab, match) => {
    setCurrentTab(tab);

    setTimeout(function () {
      setKeyword(match);
      setDescOpen(tab);

      if (expanded) scrollToTop();
    }, 50);
  };

  const scrollTo = (ref) => ref?.current?.scrollIntoView();
  const scrollToTop = () =>
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  return (
    <Box
      sx={{
        display: visible ? "block" : "none",
        width: "100%",
      }}
    >
      {messages?.length === 0 ? (
        <Box>
          <BrandingBox
            branding={branding}
            brandingLoaded={brandingLoaded}
            userData={userData}
          />
          <Options
            sendMessage={handleSendRequest}
            user={user}
            userData={userData}
            submitInfo={submitInfo}
            setSubmitInfo={setSubmitInfo}
          />
        </Box>
      ) : null}
      <Box sx={{ overflow: "hidden" }}>
        {/* {false ? (
          <Box>
            <ArrowDropUpRounded
              color="action"
              sx={{ position: "absolute", top: 0, right: -7, zIndex: 99 }}
            />
          </Box>
        ) : null} */}

        {/* <ArrowDropDown
          color="action"
          sx={{
            position: "absolute",
            bottom: 72,
            right: -5,
            zIndex: 99,
            fontSize: 20,
          }}
        /> */}

        <ChatWrapper $full={tabs?.length === 0} $expanded={expanded}>
          <MessageList
            typingIndicator={
              isTyping ? (
                <TypingIndicator content="HealthDesk is typing" />
              ) : null
            }
          >
            {messages?.map((message, i) => {
              let parsedText = message.message;

              parsedText = reactStringReplaceRecursive(
                parsedText,
                /#### (.*?)\n/gm,
                (match, k) => (
                  <h4 style={{ margin: "10px 0 15px" }} key={`k_${k}`}>
                    {match}
                  </h4>
                )
              );

              parsedText = reactStringReplaceRecursive(
                parsedText,
                /### (.*?)\n/gm,
                (match, k) => (
                  <h3 style={{ marginBottom: "10px" }} key={`k_${k}`}>
                    {match}
                  </h3>
                )
              );

              parsedText = reactStringReplaceRecursive(
                parsedText,
                bold,
                (match, i) => <strong key={`i_${i}`}>{match}</strong>
              );

              // Parse medications and diseases, but only for messages from GPT
              if (message.sender === "HealthDesk") {
                parsedText = reactStringReplaceRecursive(
                  parsedText,
                  medicationsList,
                  (match, j) => (
                    <span
                      key={`j_${j}`}
                      className="fLink"
                      onClick={() => openDesc("medications", match)}
                    >
                      {match}
                    </span>
                  )
                );

                parsedText = reactStringReplaceRecursive(
                  parsedText,
                  diseasesList,
                  (match, m) =>
                    match.length > 4 ? (
                      <span
                        key={`m_${m}`}
                        className="fLink"
                        onClick={() => openDesc("diseases", match)}
                      >
                        {match}
                      </span>
                    ) : (
                      match
                    )
                );
              }

              // Give the latest message a ref so we can scroll to it
              const itemProps =
                messages.length - 1 === i ? { ref: messageRef } : {};

              return (
                <Message
                  key={`message_${i}`}
                  model={{ direction: message.direction, type: "custom" }}
                  style={{
                    // paddingBottom: message.sender === "HealthDesk" ? "40px" : 0,
                    position: "relative",
                  }}
                >
                  <Message.CustomContent>
                    <div {...itemProps} />
                    {parsedText}

                    {/* <Box sx={{ position: "absolute", bottom: "10px", left: "0" }}>
                    <VoteButtons
                      message={message}
                      reported={reported}
                      handleFeedback={handleFeedback}
                      i={i}
                    />
                  </Box> */}
                  </Message.CustomContent>
                </Message>
              );
            })}
          </MessageList>
          <MessageInput
            disabled={rateLimited}
            placeholder={
              rateLimited
                ? `You have used up your ${limit} messages/day.`
                : "Message HealthDesk"
            }
            attachButton={false}
            onSend={handleSendRequest}
            style={{ zIndex: 99999999999 }}
          />
        </ChatWrapper>
      </Box>
    </Box>
  );
};

export default Chatbot;

const Disclaimer = () => (
  <Typography variant="body2" sx={{ color: "#1B4685", textAlign: "center" }}>
    HLTHDSK Medical SuperIntelligence is expert-curated artificial intelligence.
    It can make mistakes and cannot provide health care. Verify all medical
    information with a health care professional.
  </Typography>
);

const BrandingBox = ({ branding, brandingLoaded, userData }) => (
  <Box sx={{ position: "absolute", top: { xs: 0, sm: "50px" }, zIndex: 99 }}>
    {branding ? (
      <Box className="branding" sx={{ textAlign: "center" }}>
        <Typography
          variant="body2"
          sx={{ fontWeight: 700, fontSize: 12, mb: 1 }}
        >
          Health Care's Help Desk is sponsored by
        </Typography>
        <a href={branding.website} target="_blank" rel="noopener">
          <img
            src={branding.logo}
            style={{ maxHeight: "90px", maxWidth: "100%" }}
          />
        </a>
        <Disclaimer />
      </Box>
    ) : userData?.role === "facility" ? (
      <div className="info" style={{ textAlign: "center" }}>
        {brandingLoaded ? (
          <>
            <Link
              component={RouterLink}
              to="/dashboard/advertising"
              underline="none"
              sx={{ mb: 2, display: "block" }}
            >
              Sponsor Health Care's Health Desk.
            </Link>
            <Disclaimer />
          </>
        ) : null}
      </div>
    ) : (
      <Disclaimer />
    )}
  </Box>
);

const VoteButtons = ({ message, reported, handleFeedback, i }) => {
  if (message.sender !== "HealthDesk") return;

  if (reported.includes(i))
    return <MessageWrapper>Thank you for your feedback.</MessageWrapper>;

  return (
    <MessageWrapper>
      <button
        onClick={() => {
          handleFeedback(message.message, i, true);
        }}
      >
        <FaThumbsUp size={18} color="#117ACA" />
      </button>
      <button
        onClick={() => {
          handleFeedback(message.message, i, false);
        }}
      >
        <FaThumbsDown size={18} color="#117ACA" />
      </button>
    </MessageWrapper>
  );
};

const Options = ({
  sendMessage,
  user,
  userData,
  submitInfo,
  setSubmitInfo,
}) => {
  const [userSettingsOpen, setUserSettingsOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const openUserSettings = () => {
    setUserSettingsOpen(!userSettingsOpen);
  };

  const closeAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSubmitted(false);
  };

  // Change the options based on the subdomain
  const subdomain = window.location.host.split(".")[0];
  let options = (
    <Box>
      <ChatButton
        onClick={() => sendMessage("Use a different language")}
        border
        style={{ fontSize: "13px" }}
      >
        Use a different language
      </ChatButton>
      <ChatButton
        onClick={openUserSettings}
        border
        style={{ fontSize: "13px" }}
      >
        Update Patient Profile
      </ChatButton>
      <ChatButton
        onClick={() => sendMessage("Help with medication costs")}
        border
        style={{ fontSize: "13px" }}
      >
        Help with Medication Costs
      </ChatButton>
      <ChatButton
        onClick={() => sendMessage("Find An Emergency Room")}
        border
        style={{ fontSize: "13px" }}
      >
        Find An Emergency Room
      </ChatButton>
    </Box>
  );

  // Queensland options
  if (subdomain === "qld")
    options = (
      <Box>
        <ChatButton
          onClick={() => sendMessage("Crying issues")}
          border
          style={{ fontSize: "13px" }}
        >
          Crying issues
        </ChatButton>
        <ChatButton
          onClick={() => sendMessage("Breathing issues")}
          border
          style={{ fontSize: "13px" }}
        >
          Breathing issues
        </ChatButton>
        <ChatButton
          onClick={() => sendMessage("Poop issues")}
          border
          style={{ fontSize: "13px" }}
        >
          Poop issues
        </ChatButton>
        <ChatButton
          onClick={() => sendMessage("Eating issues")}
          border
          style={{ fontSize: "13px" }}
        >
          Eating issues
        </ChatButton>
      </Box>
    );

  return (
    <>
      <UserProfileSettings
        user={user}
        data={userData}
        visible={userSettingsOpen}
        setSubmitted={setSubmitted}
        close={() => setUserSettingsOpen(false)}
      />
      <OptionsWrapper>
        <img src={logo} alt="HealthDesk Logo" />
        <h3>HealthDesk</h3>
        <p style={{ fontWeight: "bold" }}>Health Care's Help Desk</p>

        {options}

        {userData?.profile ? (
          <div className="disclaimer">
            <p>
              <label>
                <input
                  type="checkbox"
                  checked={submitInfo}
                  onChange={() => setSubmitInfo(!submitInfo)}
                  style={{ fontSize: "13px" }}
                />
                Your patient profile will be submitted with these queries.
              </label>
            </p>
          </div>
        ) : null}
      </OptionsWrapper>

      {submitted ? (
        <Snackbar open={submitted} autoHideDuration={6000} onClose={closeAlert}>
          <Alert
            onClose={closeAlert}
            severity="success"
            variant="standard"
            sx={{ width: "100%" }}
          >
            {submitted}
          </Alert>
        </Snackbar>
      ) : null}
    </>
  );
};

const ChatbotSignup = () => {
  return (
    <OptionsWrapper>
      <img src={logo} alt="HealthDesk Logo" />
      <h3>HealthDesk</h3>
      <p style={{ fontWeight: "bold" }}>Health Care's Help Desk</p>
      <p>
        Get expert-level medical recommendations from home. With HealthDesk's
        Medical SuperIntelligence, you can describe your symptoms, get advice on
        medications, and more.
      </p>
      <p>
        Sign up now for a free 24-hour free trial. No credit card necessary.
      </p>
      <Link to="/register">
        <Button>Sign up now</Button>
      </Link>
      <TextLink $center to="/login">
        Login
      </TextLink>
    </OptionsWrapper>
  );
};
