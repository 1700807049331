import React, { useState, useContext } from "react";
import Loading from "../Loading";
import { AuthContext } from "../context/Auth";
import { Navigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import firebaseApp from "../../firebase";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import LogoLarge from "../styled/LogoLarge";
import AuthWrapper from "./AuthWrapper";

import background from "../../bgLarge.jpg";
import { FormHelperText, Grid, Link, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import MuBox from "components/mui/MuBox";
import { ChatRounded, LocalHospitalRounded } from "@mui/icons-material";

const InputWrapper = styled.div`
  border-top: ${(props) => (props.top ? "0.55px solid #c8c7cc" : "none")};
  padding-top: ${(props) => (props.top ? "10px" : "0")};
  border-bottom: 0.55px solid #c8c7cc;
  margin-bottom: 10px;
`;

const Label = styled.label`
  display: block;
  width: 100%;
  font-size: ${({ $large }) => ($large ? "14px" : "12px")};
  /* color: #000; */
`;

const Input = styled.input`
  display: block;
  width: 100%;
  background: transparent;
  border: 0;
  height: 40px;
  font-size: 17px;
  font-weight: 400;
`;

const Error = styled.span`
  display: block;
  color: crimson;
  padding-bottom: 5px;
  font-size: 14px;
`;

const LoginForm = (onLogin) => {
  const isFunction = (functionToCheck) => {
    return (
      functionToCheck &&
      {}.toString.call(functionToCheck) === "[object Function]"
    );
  };
  const [firebaseErrors, setFirebaseErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const onSubmit = ({ email, password }) => {
    setLoading(true);

    // Create user and create database entry for user/booklist
    firebaseApp
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        //I have no idea why the first code works on the login page and everywhere else needs the second code
        if (isFunction(onLogin)) {
          onLogin();
        } else if (isFunction(onLogin.onLogin)) {
          onLogin.onLogin();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        let errorMessage;
        switch (error.code) {
          case "auth/invalid-credential":
            errorMessage =
              "Your email address or password is incorrect. Please try again.";
            break;
          default:
            errorMessage = error.message;
        }

        setFirebaseErrors(errorMessage);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="email"
        control={control}
        defaultValue={""}
        rules={{
          required: "Email is required.",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: "Invalid email address.",
          },
        }}
        render={({ field }) => (
          <TextField
            id="email"
            label="Email"
            type="email"
            InputLabelProps={{ shrink: true }}
            variant="standard"
            fullWidth
            error={!!errors?.email}
            helperText={errors?.email?.message}
            sx={{ pb: 2 }}
            {...field}
          />
        )}
      />

      <Controller
        name="password"
        control={control}
        defaultValue={""}
        rules={{
          required: "Password is required.",
        }}
        render={({ field }) => (
          <TextField
            id="password"
            label="Password"
            type="password"
            InputLabelProps={{ shrink: true }}
            variant="standard"
            fullWidth
            error={!!errors?.password}
            helperText={errors?.password?.message}
            sx={{ pb: 1 }}
            {...field}
          />
        )}
      />

      {firebaseErrors ? (
        <FormHelperText error={true}>{firebaseErrors}</FormHelperText>
      ) : null}

      <LoadingButton
        type="submit"
        loading={loading}
        disabled={loading}
        variant="contained"
        fullWidth
        size="large"
        sx={{ mt: 2, mb: 3 }}
      >
        Log In
      </LoadingButton>
    </form>
  );
};

const Login = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  if (user) {
    return <Navigate to="/dashboard" />;
  }

  if (user !== null) {
    return <Loading />;
  }

  return (
    <AuthWrapper background={background}>
      <LogoLarge />

      <LoginForm
        onLogin={() => {
          return <Navigate to="/dashboard" />;
        }}
      />

      <Grid container sx={{ mb: 3 }}>
        <Grid item xs>
          <Link component={RouterLink} to="/" underline="none" variant="body2">
            Return to Map
          </Link>
        </Grid>
        <Grid item>
          <Link
            component={RouterLink}
            to="/reset-password"
            underline="none"
            variant="body2"
          >
            Reset password
          </Link>
        </Grid>
      </Grid>

      <MuBox
        icon={<ChatRounded fontSize="large" sx={{ color: "#117aca" }} />}
        title="Create Patient Account"
        description="Gain access to Medical SuperIntelligence, check your symptoms, and get a preliminary differential diagnosis for your concerns."
        links={[
          {
            title: "Register Patient Account",
            onClick: () => {
              navigate("/msi/register");
            },
          },
        ]}
      />

      <MuBox
        icon={
          <LocalHospitalRounded fontSize="large" sx={{ color: "#117aca" }} />
        }
        title="Create Facility Account"
        description="Put your facility on the map and help your patients get health care more efficiently."
        links={[
          {
            title: "Register Facility Account",
            onClick: () => {
              navigate("/register");
            },
          },
        ]}
      />

      <Grid container sx={{ mb: 3 }}>
        <Grid item xs>
          <Link
            href="/terms-of-use"
            underline="none"
            variant="body2"
            target="_blank"
          >
            Terms of Use
          </Link>
        </Grid>
        <Grid item>
          <Link
            href="/privacy-policy"
            underline="none"
            variant="body2"
            target="_blank"
          >
            Privacy Policy
          </Link>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export { Login, LoginForm };
