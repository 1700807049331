import React, { useContext, useState, useEffect, useRef } from "react";
import Loading from "./Loading";
import EmailConfirmation from "./EmailConfirmation";
import { Navigate } from "react-router";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import firebaseApp, { db } from "../firebase";
import { AuthContext } from "./context/Auth";

import Advertising from "./dashboard/Advertising";
import Billing from "./dashboard/Billing";
import AdminTimeForm from "./dashboard/AdminTimeForm";
import Scheduler from "./dashboard/Scheduler";
import WaitingRoom from "./queue/WaitingRoom";
import BetaDisclaimer from "./dashboard/BetaDisclaimer";

import VaccineTextSequence from "./vaccine/TextSequence";
import VirtualRegistrationSettings from "./vaccine/queue/VirtualRegistrationSettings";

import DashboardInner from "./styled/DashboardInner";
import PatientDashboard from "./chatbot/PatientDashboard";
import PatientBilling from "./chatbot/PatientBilling";
import MuDrawer from "./mui/MuDrawer";
import MuWrapper from "./mui/MuWrapper";
import { ChatProvider } from "./context/Chat";
import { LocationContext } from "./context/Location";
import { Box, CircularProgress } from "@mui/material";

const DashboardContent = (props) => {
  if (!props.user.emailVerified)
    return (
      <MuWrapper>
        <Routes>
          <Route
            path="/*"
            exact
            element={
              <EmailConfirmation
                firebase={props.firebaseApp}
                loading={props.loading}
                submitted={props.submitted}
                resendEmail={props.resendEmail}
                dbError={props.dbError}
              />
            }
          />
        </Routes>
      </MuWrapper>
    );

  if (!props.subscription)
    return (
      <MuWrapper>
        <Billing
          uid={props.user.uid}
          role={props.userData?.role}
          user={props.user}
        />
      </MuWrapper>
    );

  // If registered as regional manager, wait for manual approval
  if (props.data.type === 2)
    return (
      <DashboardInner>
        <p>
          We are currently processing your application. You will be sent an
          email when you have been approved.
        </p>
      </DashboardInner>
    );

  if (props.subscription === "facility") return props.children;
  if (props.subscription === "patient")
    return (
      <Routes>
        <Route
          exact
          path="/"
          element={
            <div>
              <PatientDashboard
                userData={props.userData}
                branding={props.data.branding}
              />
            </div>
          }
        />
        <Route
          path="/msi"
          element={
            <div style={{ height: "100dvh" }}>
              <PatientDashboard
                userData={props.userData}
                branding={props.data.branding}
              />
            </div>
          }
        />
        <Route
          path="/account"
          element={<PatientBilling userData={props.userData} />}
        />
      </Routes>
    );
};

const Dashboard = () => {
  const boxRef = useRef(null);
  const [waitingRoomLoading, setWaitingRoomLoading] = useState();
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [data, setData] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [dbError, setDbError] = useState(false);

  const { user, subscription, userData, userLoading } = useContext(AuthContext);
  const { location, locationLoaded } = useContext(LocationContext);

  useEffect(() => {
    if (locationLoaded && location) {
      getTimes();
    }
  }, [locationLoaded, location]);

  const setWaitingRoomCap = (event) => {
    const value = event.target.value;

    setData({ ...data, queueCap: value });

    db.collection("locations")
      .doc(String(data.id))
      .update({
        queueCap: value,
      })
      .then(function () {
        console.log("success");
      });
  };

  const deletePatient = (patient) => {
    setWaitingRoomLoading(true);
    let newQueue = [...data.queue];
    const index = newQueue.indexOf(patient);
    newQueue.splice(index, 1);

    const batch = db.batch();

    batch.update(db.collection("locations").doc(String(data.id)), {
      queue: newQueue,
    });
    //This was disabled on 2-11-2021. Im gonna leave it here in case Dan changes his mind
    //if (data.queue.length > 1) {
    //  const next = newQueue.sort((a, b) => a.id - b.id)[0];
    //
    //  batch.set(db.collection("messages").doc(), {
    //    to: next.phone,
    //    body: `You are next in the virtual queue. Please enter the ${data.title} ${data.type} now.`,
    //  });
    //}

    batch.commit().then(() => {
      console.log("deleted");
      setWaitingRoomLoading(false);
    });
  };

  const textPatient = (patient, type) => {
    setWaitingRoomLoading(true);

    const batch = db.batch();

    let newQueue = [...data.queue];
    const index = newQueue.indexOf(patient);
    const updatedPatient =
      type === "call"
        ? { ...patient, called: Date.now() }
        : { ...patient, registered: Date.now() };
    newQueue[index] = updatedPatient;

    batch.update(db.collection("locations").doc(String(data.id)), {
      queue: newQueue,
    });

    const message =
      type === "call"
        ? `${data.title} is ready for you to come in. The address is ${data.address}. Show this text to the welcome desk. You are "Patient ${patient.id}".`
        : `Please expect a registration phone call shortly from ${data.title}.`;

    batch.set(db.collection("messages").doc(), {
      to: patient.phone,
      body: message,
    });

    batch.commit().then(() => {
      setWaitingRoomLoading(false);
      setData({ ...data, queue: newQueue });
    });
  };

  const resendEmail = () => {
    setLoading(true);

    user
      .sendEmailVerification()
      .then(() => {
        setLoading(false);
        setSubmitted(true);
      })
      .catch((error) => {
        setLoading(false);
        setDbError(error.message);

        console.log(error.message);
      });
  };

  const getTimes = () => {
    const initialData = location;

    if (initialData.waitTimes) {
      const adminTime = initialData.waitTimes
        .reverse()
        .find((time) => time.dashboard);
      const lab = adminTime?.lab || null;
      const xray = adminTime?.xray || null;
      const ultrasound = adminTime?.ultrasound || null;
      const ct = adminTime?.ct || null;
      const mri = adminTime?.mri || null;
      const telehealth = adminTime?.telehealth || null;
      const customPhone = adminTime?.customPhone || null;
      const dashboard = adminTime?.dashboard || null;
      const newData = {
        ...initialData,
        lab,
        xray,
        ultrasound,
        ct,
        mri,
        telehealth,
        customPhone,
        dashboard,
      };
      setData(newData);
    } else {
      setData(initialData);
    }
  };

  const toggleWaitingRoom = () => {
    const value = !data?.queueEnabled;

    setData({ ...data, queueEnabled: value });

    db.collection("locations")
      .doc(String(data.id))
      .update({
        queueEnabled: value,
      })
      .then(function () {
        console.log("success");
      });
  };

  if (user === null) {
    return <Navigate to="/login/" />;
  }

  if (!user || userLoading)
    return (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );

  let only_vaccines = data.type === 3;
  if (
    only_vaccines &&
    window.location.pathname.replaceAll("/", "") === "dashboard"
  ) {
    window.location = "/dashboard/vaccine/queue";
    return;
  }

  return (
    <ChatProvider>
      <MuDrawer boxRef={boxRef}>
        <DashboardContent
          user={user}
          loading={loading}
          subscription={subscription}
          userLoading={userLoading}
          userData={userData}
          data={data}
          submitted={submitted}
          firebaseApp={firebaseApp}
          dbError={dbError}
          resendEmail={resendEmail}
        >
          {/* <DashboardNav
            active={user.emailVerified && isAdmin && data.type !== 2}
            user={user}
          /> */}

          <Routes>
            <Route exact path="/" element={<AdminTimeForm data={data} />} />

            <Route
              path="/sequence"
              element={
                <div className="inner">
                  <VirtualRegistrationSettings data={data} setData={setData} />
                  <hr></hr>
                  <VaccineTextSequence data={data} setData={setData} />
                </div>
              }
            />

            {/* <Route
              path="/vaccine/queue"
              element={
                <VaccineWaitingRoom
                  data={data}
                  setData={setData}
                  uid={user.uid}
                />
              }
            />

            <Route
              path="/vaccine/sequence"
              element={
                <>
                  <VirtualRegistrationSettings
                    data={data}
                    setData={setData}
                    vaccine
                  />
                  <hr></hr>
                  <VaccineTextSequence data={data} setData={setData} vaccine />
                </>
              }
            />

            <Route
              path="/vaccine/administration_records"
              element={<AdministrationRecords data={data} setData={setData} />}
            />

            <Route
              path="/vaccine/waiting_list"
              element={<WaitingList location={data} setLocation={setData} />}
            /> */}

            <Route
              path="/queue"
              element={
                <WaitingRoom
                  data={data}
                  setData={setData}
                  defaultValue={data?.queueEnabled}
                  queue={data?.queue}
                  toggleWaitingRoom={toggleWaitingRoom}
                  textPatient={textPatient}
                  deletePatient={deletePatient}
                  waitingRoomLoading={waitingRoomLoading}
                  setWaitingRoomCap={setWaitingRoomCap}
                  cap={data?.queueCap}
                />
              }
            />
            <Route
              path="/schedule"
              element={<Scheduler data={data} setData={setData} db={db} />}
            />
            <Route
              exact
              path="/msi"
              element={
                <div style={{ height: "100dvh" }}>
                  <PatientDashboard
                    userData={userData}
                    branding={data.branding}
                    boxRef={boxRef}
                  />
                </div>
              }
            />

            <Route
              path="/account"
              element={<Billing uid={user.uid} subscription={subscription} />}
            />

            <Route path="/advertising/*" element={<Advertising />} />
          </Routes>

          <BetaDisclaimer />
        </DashboardContent>
      </MuDrawer>
    </ChatProvider>
  );
};

export default Dashboard;
