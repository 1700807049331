import React, { useState } from "react";

import Loading from "../Loading";
import Text from "../styled/Text";

import QueuePatient from "./QueuePatient";
import ControlledInputField from "components/styled/ControlledInputField";
import {
  Alert,
  Box,
  CircularProgress,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

const WaitingRoom = ({
  data,
  setData,
  queue,
  defaultValue,
  toggleWaitingRoom,
  textPatient,
  deletePatient,
  waitingRoomLoading,
  setWaitingRoomCap,
  cap,
}) => {
  return (
    <div className="inner">
      <Typography
        variant="h4"
        component="h2"
        sx={{ mt: { xs: 1, sm: 5 }, mb: 2 }}
      >
        Your Clinic's Queue
      </Typography>

      <Typography variant="body" sx={{ display: "block", mt: 1, mb: 4 }}>
        Have patients virtually register to reserve their spot, and let them
        know when they're ready to be seen via text message.
      </Typography>

      {!data.id ? (
        <CircularProgress />
      ) : (
        <Box>
          <FormControlLabel
            value="start"
            control={
              <Switch
                color="primary"
                onChange={toggleWaitingRoom}
                defaultChecked={defaultValue}
              />
            }
            label="Use virtual waiting room?"
            labelPlacement="start"
            sx={{ ml: 0, mb: 2 }}
          />

          {waitingRoomLoading ? <CircularProgress /> : null}

          {defaultValue ? (
            <Box sx={{ maxWidth: 420, mb: 2 }}>
              <TextField
                id="cap"
                name="cap"
                label="Maximum Queue"
                type="number"
                variant="standard"
                onChange={setWaitingRoomCap}
                defaultValue={cap}
                fullWidth
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2 }}
              />

              {queue?.length ? (
                <div>
                  {queue.map((patient) => (
                    <QueuePatient
                      key={patient.date}
                      data={data}
                      setData={setData}
                      patient={patient}
                      textPatient={textPatient}
                      deletePatient={deletePatient}
                    />
                  ))}
                </div>
              ) : (
                <Alert severity="info">
                  No patients are currently in queue.
                </Alert>
              )}
            </Box>
          ) : null}
        </Box>
      )}
    </div>
  );
};

export default WaitingRoom;
