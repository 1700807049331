import React, { useEffect, useState } from "react";
import firebaseApp, { db } from "../../firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import "firebase/compat/storage";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Chip,
  Box,
  Alert,
  CircularProgress,
  Link,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useStripe } from "@stripe/react-stripe-js";
import capitalize from "components/helpers/capitalize";

const PromoteFacilitiesSettings = ({
  user,
  location,
  switchToAddress,
  switchToBranding,
  open,
  close,
}) => {
  const [loading, setLoading] = useState(true);
  const [addOpen, setAddOpen] = useState(false);
  const [zipsAdded, setZipsAdded] = useState(false);
  const [subLoading, setSubLoading] = useState(true);
  const [subscription, setSubscription] = useState();
  const [zips, setZips] = useState([]);

  // Check to see if we have a Stripe subscription yet, if yes, then no need to go through the whole Stripe checkout process again
  const getSubscription = () => {
    if (!user || subscription) return;

    // subscription will be null if not loaded yet
    if (subLoading == true)
      db.collection("users")
        .doc(user.uid)
        .collection("subscriptions")
        .where("role", "==", "promote_facilities")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            setSubscription(doc.data());
          });

          setSubLoading(false);
        });
  };

  const getZips = () => {
    if (!location || !user) return;

    setZips(user.zipAds?.filter((z) => z.status !== "pending") || []);

    // db.collection("zips")
    //   .where("group_users", "array-contains", user.uid)
    //   .get()
    //   .then((querySnapshot) => {
    //     let docs = [];

    //     querySnapshot.forEach(function (doc) {
    //       if (doc.data()) {
    //         const data = doc.data();

    //         docs.push(data);
    //       }
    //     });

    // setZips(docs);
    setLoading(false);
    // });
  };

  useEffect(() => {
    if (open) getZips();
  }, [open, location, user]);

  // Check if we have a promote_facilities subscription that exists yet
  useEffect(() => {
    if (open) getSubscription();
  }, [open, location, user]);

  return (
    <>
      <Dialog open={open} onClose={close} maxWidth="md" fullWidth>
        <DialogTitle>Choose ZIP Codes</DialogTitle>

        <DialogContent>
          <DialogContentText variant="body2" sx={{ mb: 2 }}>
            Your facilities will appear under "Facility Type" when users are
            searching for clinics on the map. You will need to pay a monthly
            recurring fee for each ZIP code you wish to advertise at.
          </DialogContentText>

          {zipsAdded ? (
            <Alert severity="success" sx={{ mt: 3, mb: 4 }}>
              Your advertisements on your selected ZIP codes have been posted.
            </Alert>
          ) : null}

          {!loading && !location?.group ? (
            <Alert severity="error">
              To advertise your facilities, please{" "}
              <Link
                color="inherit"
                sx={{ cursor: "pointer" }}
                onClick={switchToAddress}
              >
                enter the name of your Facility Group.
              </Link>
            </Alert>
          ) : (
            <ZipTable
              loading={loading}
              subLoading={subLoading}
              subscription={subscription}
              user={user}
              location={location}
              zips={zips}
              setZips={setZips}
              setAddOpen={setAddOpen}
              switchToBranding={switchToBranding}
            />
          )}

          <AddZipCode
            user={user}
            subscription={subscription}
            location={location}
            setZipsAdded={setZipsAdded}
            open={addOpen}
            close={() => setAddOpen(false)}
            zips={zips}
            setZips={setZips}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={close} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AddZipCode = ({
  user,
  subscription,
  location,
  open,
  close,
  zips,
  setZips,
  setZipsAdded,
}) => {
  const stripe = useStripe();
  const {
    control,
    handleSubmit,
    formState: { errors },
    unregister,
  } = useForm();
  const [loading, setLoading] = useState(false);

  const [newZips, setNewZips] = useState(1);

  const addNewZip = () => {
    if (newZips > 9) return;
    setNewZips(newZips + 1);
  };

  const removeNewZip = () => {
    if (newZips === 1) return;

    unregister("zip_" + newZips);
    setNewZips(newZips - 1);
  };

  const onSubmit = (submittedZips) => {
    const slots = Object.keys(submittedZips).length;

    setLoading(true);

    // If a stripe subscription already exists, just update the quantity through the backend function
    if (subscription) {
      const newQuantity = zips.length + slots;

      const zipsToAdd = {
        zips,
        zip: Object.values(submittedZips),
        uid: user.uid,
        group: { name: location.group, ...location.branding },
        branding: location.branding,
        quantity: newQuantity,
        subId: subscription.items[0].subscription,
        operation: "add",
      };

      const newZips = Object.values(submittedZips).map((z) => {
        return { zip: z, status: "active" };
      });

      db.collection("users")
        .doc(user.uid)
        .collection("zipSubs")
        .doc()
        .set(zipsToAdd)
        .then(() => {
          setZips(zips.concat(newZips).sort((a, b) => b - a));
          setZipsAdded(true);
          close();
        });
    }

    // If there is no subscription yet we have to take the customer to the checkout portal, otherwise just adjust the quantity
    if (!subscription) {
      const checkout = {
        price: "price_1PlXiDEQXF105V0ldrOLZKAg",
        quantity: slots,
        payment_method_collection: "if_required",
        success_url: window.location.origin + "/dashboard/advertising/success", // redirect user to this screen after
        cancel_url: window.location.origin + "/dashboard/advertising",
      };

      const pendingZips = Object.values(submittedZips).map((z) => {
        return {
          zip: z,
          status: "pending",
          group: location.group,
          branding: location.branding,
        };
      });

      db.collection("users")
        .doc(user.uid)
        .update({
          zipAds: pendingZips,
        })
        .then(() => {
          db.collection("users")
            .doc(user.uid)
            .collection("checkout_sessions")
            .add(checkout)
            .then((docRef) => {
              docRef.onSnapshot((snap) => {
                const { error, sessionId } = snap.data();
                if (error) {
                  alert(`An error occurred: ${error.message}`);
                }

                if (sessionId) {
                  stripe.redirectToCheckout({ sessionId });
                }
              });
            });
        });
    }
    // TODO: Handle errors with catch
  };

  return (
    <Dialog open={open} onClose={close}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Add ZIP Code</DialogTitle>

        <DialogContent>
          <DialogContentText variant="body2" sx={{ mb: 2 }}>
            Purchase this ZIP code to have your facilities be shown as a
            category to all users in the area.{" "}
            <strong>
              Each additional ZIP code costs <strong>$50/month</strong>.
            </strong>
          </DialogContentText>

          <DialogContentText variant="body2" sx={{ mb: 2 }}>
            You will be taken to Stripe to complete your payment.
          </DialogContentText>

          {Array.from(Array(newZips), (e, i) => {
            const num = i + 1;

            return (
              <Controller
                key={`zip_${num}`}
                name={`zip_${num}`}
                control={control}
                rules={{
                  required: "ZIP code is required.",
                  pattern: {
                    value: /^\d{5}?$/i,
                    message: "Please enter a valid 5-digit ZIP code.",
                  },
                }}
                defaultValue={""}
                render={({ field }) => (
                  <TextField
                    id="zip"
                    label={newZips > 1 ? `ZIP Code #${num}` : `ZIP Code`}
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    fullWidth
                    error={!!errors?.["zip_" + num]}
                    helperText={errors?.["zip_" + num]?.message}
                    sx={{ mt: 2 }}
                    {...field}
                  />
                )}
              />
            );
          })}

          {newZips < 10 ? (
            <Box sx={{ mt: 2 }}>
              <Button
                disabled={loading}
                onClick={addNewZip}
                variant="contained"
                sx={{ mr: 2 }}
              >
                Add Another ZIP
              </Button>

              {newZips > 1 ? (
                <Button
                  disabled={loading}
                  onClick={removeNewZip}
                  variant="text"
                >
                  Remove ZIP
                </Button>
              ) : null}
            </Box>
          ) : null}

          <Box sx={{ textAlign: "right", mt: 2, mb: 0 }}>
            <Typography variant="body">
              <strong>
                You will be billed ${(zips.length + newZips) * 50}/month.
              </strong>
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={close}>
            Close
          </Button>
          {!subscription ? (
            <LoadingButton
              loading={loading}
              type="submit"
              disabled={loading}
              autoFocus
              variant="contained"
            >
              Proceed to Payment
            </LoadingButton>
          ) : (
            <LoadingButton
              loading={loading}
              type="submit"
              disabled={loading}
              autoFocus
              variant="contained"
            >
              Add ZIP Codes
            </LoadingButton>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

const ZipTable = ({
  loading,
  subscription,
  subLoading,
  user,
  location,
  zips,
  setZips,
  setAddOpen,
  switchToBranding,
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmed, setConfirmed] = useState();
  const [removingZip, setRemovingZip] = useState();

  const confirmRemoveZip = (zip) => {
    setConfirmOpen(true);
    setRemovingZip(zip);
  };

  const removeZip = () => {
    setConfirmed(true);

    // The current amount of zips, minus one
    const newQuantity = zips.length - 1;

    const zipToRemove = {
      zips,
      zip: [removingZip.zip],
      uid: user.uid,
      group: location.group,
      quantity: newQuantity,
      subId: subscription?.items[0]?.subscription,
      operation: "remove",
    };

    db.collection("users")
      .doc(user.uid)
      .collection("zipSubs")
      .doc()
      .set(zipToRemove)
      .then(() => {
        console.log("zip has been removed");
        setZips(zips.filter((z) => z.zip !== removingZip.zip));
        setConfirmed(false);
        setConfirmOpen(false);
        setRemovingZip();
      });
  };

  const close = () => {
    if (confirmed) return;
    setConfirmOpen(false);
  };

  if (loading || subLoading)
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );

  if (
    !loading &&
    location &&
    !location?.branding?.logo &&
    !location?.branding?.website &&
    !location?.branding?.tagline
  )
    return (
      <Alert severity="error">
        Your advertising will not show until you add your branding. Please{" "}
        <Link
          color="inherit"
          sx={{ cursor: "pointer" }}
          onClick={switchToBranding}
        >
          add your branding
        </Link>
        .
      </Alert>
    );

  return (
    <Box>
      <Box sx={{ textAlign: "right" }}>
        <Button
          variant="contained"
          sx={{ mb: 2 }}
          onClick={() => setAddOpen(true)}
        >
          Add New ZIP
        </Button>
      </Box>

      {zips.length === 0 ? (
        <Alert severity="info">
          You don't currently have any ZIP codes you're advertising in.
        </Alert>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ZIP Code</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {zips.map((zip) => (
                <TableRow key={zip.zip}>
                  <TableCell>{zip.zip}</TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      label={capitalize(zip.status)}
                      color={zip.status === "active" ? "success" : "default"}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      onClick={() => confirmRemoveZip(zip)}
                      color="error"
                      size="small"
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog open={confirmOpen} onClose={close}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Removing this ZIP will remove your advertisement from this ZIP code.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus disabled={confirmed} onClick={close}>
            Cancel
          </Button>
          <LoadingButton
            loading={confirmed}
            type="submit"
            disabled={confirmed}
            onClick={removeZip}
            color="error"
            variant="contained"
          >
            Yes, remove this ZIP
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PromoteFacilitiesSettings;
