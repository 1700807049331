import { useContext, useState } from "react";
import { MainContainer } from "@chatscope/chat-ui-kit-react";
import firebase from "firebase/compat/app";

import logo from "logo-icon.png";
import styled from "styled-components";
import { Link } from "react-router-dom";
import TextLink from "../styled/TextLink";
import Button from "../styled/Button";
import Loading from "components/Loading";
import { db } from "../../firebase";
import Chatbot from "./Chatbot";
import { AuthContext } from "components/context/Auth";
import { ChatContext } from "components/context/Chat";
import ContextBox from "./ContextBox";
import { Box } from "@mui/material";

const OptionsWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 100px;
  bottom: 100px;
  text-align: center;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;

  img {
    max-width: 84px;
    margin: 0 auto;
  }

  h3 {
    text-transform: uppercase;
    margin-bottom: 0;
  }

  div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
  }
`;

const PatientDashboard = ({ userData, branding, boxRef }) => {
  const { user, subscription, userLoading } = useContext(AuthContext);
  const { messages } = useContext(ChatContext);
  const [tabs, setTabs] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [currentTab, setCurrentTab] = useState("home");
  const [loading, setLoading] = useState(false);

  const sendToPortal = async () => {
    setLoading(true);

    const functionRef = firebase
      .app()
      .functions("us-central1")
      .httpsCallable("ext-firestore-stripe-payments-createPortalLink");
    const { data } = await functionRef({
      returnUrl: window.location.href,
    });
    window.location.assign(data.url);
  };

  if (!user) {
    return <ChatbotSignup />;
  }

  if (!userLoading && subscription === undefined) {
    return (
      <OptionsWrapper>
        <p style={{ fontSize: "15px", fontWeight: "bold" }}>
          You're not currently subscribed.
        </p>
        <p style={{ fontSize: "15px", marginBottom: "25px" }}>
          To use the Medical SuperIntelligence, please sign up for a
          subscription below.
        </p>
        <Link to="/dashboard/account">
          <Button>Subscribe</Button>
        </Link>
      </OptionsWrapper>
    );
  }

  if (!userLoading && subscription === null) {
    return (
      <OptionsWrapper>
        <p style={{ fontSize: "15px", fontWeight: "bold" }}>
          Your subscription has expired.
        </p>
        <p style={{ fontSize: "15px", marginBottom: "25px" }}>
          To continue using the Medical SuperIntelligence, please sign up for a
          subscription below.
        </p>
        <Button type="submit" onClick={sendToPortal} disabled={loading}>
          {loading ? <Loading /> : "Manage Subscription"}
        </Button>
      </OptionsWrapper>
    );
  }

  if (messages == null)
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        <Loading page />
      </div>
    );

  let xsSize = "calc(80dvh - 60px)";
  let smSize = "80dvh";

  if (expanded) {
    xsSize = "calc(100dvh - 110px)";
    smSize = "calc(100dvh - 50px)";
  }

  if (tabs.length === 0) {
    xsSize = "calc(100dvh - 86px)";
    smSize = "calc(100dvh - 24px)";
  }

  return (
    <Box>
      <ContextBox
        messages={messages}
        branding={branding}
        user={user}
        userData={userData}
        tabs={tabs}
        setTabs={setTabs}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        expanded={expanded}
        setExpanded={setExpanded}
        boxRef={boxRef}
      />
      <Box
        sx={{
          height: {
            xs: xsSize,
            sm: smSize,
          },
          display: expanded ? "none" : "block",
        }}
      >
        <MainContainer
          style={{
            height: "100%",
            border: "none",
            paddingTop: "1.5rem",
          }}
        >
          <Chatbot
            visible={!expanded}
            branding={branding}
            user={user}
            userData={userData}
            setCurrentTab={setCurrentTab}
            expanded={expanded}
            tabs={tabs}
            boxRef={boxRef}
          />
        </MainContainer>
      </Box>
    </Box>
  );
};

export default PatientDashboard;

const ChatbotSignup = () => {
  return (
    <OptionsWrapper>
      <img src={logo} alt="HealthDesk Logo" />
      <h3>HealthDesk</h3>
      <p style={{ fontWeight: "bold" }}>Health Care's Help Desk</p>
      <p>
        Get expert-level medical recommendations from home. With HealthDesk's
        Medical SuperIntelligence, you can describe your symptoms, get advice on
        medications, and more.
      </p>
      <p>
        Sign up now for a free 24-hour free trial. No credit card necessary.
      </p>
      <Link to="/register">
        <Button>Sign up now</Button>
      </Link>
      <TextLink $center to="/login">
        Login
      </TextLink>
    </OptionsWrapper>
  );
};
